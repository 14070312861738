import React from 'react'

function SummerSips() {
  return (
    <div style={{
        position: 'relative',
        width: '100%',
        height: 0,
        paddingTop: '56.2225%',
        paddingBottom: 0,
        boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
        marginTop: '1.6em',
        marginBottom: '0.9em',
        overflow: 'hidden',
        borderRadius: '8px',
        willChange: 'transform',
    }}>
        <iframe
            title="Summer Sips"
            // allowFullScreen
            loading="lazy"
            src="https:&#x2F;&#x2F;www.canva.com&#x2F;design&#x2F;DAGAqRApDRE&#x2F;h4uMtzeSqAKejz0b6X5_hw&#x2F;view?embed"
            style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, border: 'none', padding: 0, margin: 0}}
        ></iframe>
    </div>
  )
}

export default SummerSips