import './styles/style.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ComingSoon } from "./pages"
import { SummerSips } from './pages/events';

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<ComingSoon />} />
        <Route exact path="/summer-sips" element={<SummerSips />} />
      </Routes>
    </Router>
    // <div className="App">
    //   <ComingSoon />
    // </div>
  );
}

export default App;
