import lottie from 'lottie-web';
import React from 'react';
import { useEffect, useRef } from 'react';
import LoadingAnimation from '../assets/lottie-files/Loading.json';

const customStyles = {
    backgroundColor: 'wheat',
    alignContent: 'center',
}

const ComingSoon = () => {
    useEffect(() => {
        lottie.loadAnimation({
            container: container.current,
			render: 'svg',
			loop: true,
			autoplay: true,
			animationData: LoadingAnimation
        });
    }, []);

    const container = useRef(null);

    return (
        <div className="page coming-soon-page">
            <div
                className="text"
                // style={customStyles}
            >
                <h3>We are Space Couch!</h3>
            </div>
            <div className="animation-container" ref={container}></div>
            <p>We are coming soon ...</p>
        </div>
    )
}

// export default ComingSoon
export default React.memo(ComingSoon);